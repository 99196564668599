import React,{useRef,useState,useEffect,useContext} from 'react'
import {UserContext} from '../context/UserContext'
import {Link} from 'react-router-dom'
import {Alert,Container,Row,Col,Form,Button,Table} from 'react-bootstrap'
//import FileUpload from './FileUpload'
import axios from 'axios'
import {FaBookOpen} from 'react-icons/fa'
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import FileUpload from './FileUpload'
// import ChallengeLang from './ChallengeLang'
// import ChallengeVideos from './ChallengeVideos'
import UserChallenges from './UserChallenges'
import UserPosts from './UserPosts'
import UserStats from './UserStats'

export default function UsersForm(props) {

  const [record,setRecord]=useState({})
  const [isLoading,setIsLoading]=useState(false)
  const [descr,setDescr]=useState('')
  
  
  useEffect(()=>{
    loadData(props.id)
  },[props.id])

  const loadData=(id)=>{
    setIsLoading(true)
    if (id!=0){
      axios.get(process.env.REACT_APP_API_SERVER+'admin/users/'+id,{
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
            }            
        })
      .then(res=>{
      // console.log(res.data.results) 
        setRecord(res.data.results)
        setDescr(res.data.results.Descr)

        setIsLoading(false)
        
      }).catch(err=>{
          setIsLoading(false)

      })
    }else{
      setRecord({
        id: 0,
        IsActive:1,
        Descr:''
      })
      setIsLoading(false)
    } 
  }

  const save=(e)=>{
    e.preventDefault()

  const obj={IsActive:record.IsActive,id:record.id}
  console.log(obj)
    axios.post(process.env.REACT_APP_API_SERVER+'admin/users',obj,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
          }            
      })
    .then(res=>{
      alert(res.data.msg)
      //props.showForm(0,false)
    }).catch(err=>{
      alert('Υπήρξε ένα πρόβλημα, παρακαλώ προσπαθήστε ξανά')
    })


  // const chDescr=(e,editor)=>{
  //   var xDescr=editor.getData()

  //  setDescr(xDescr)
}

const deleteRecord=()=>{
   if (window.confirm('ΔΙΑΓΡΑΦΗ ΧΡΗΣΤΗ!!!! ΣΙΓΟΥΡΑ???')){
      axios.delete(process.env.REACT_APP_API_SERVER+'admin/users/'+record.id,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
              }            
          })
        .then(res=>{
          if (res.data.err==0){
             // props.loadData()
             props.showForm(0,false)
          }else{
            alert(res.data.msg)
          }

          
      })
   }
}

const loadImage=(id)=>{
  const d=Date.now()
  //alert(`${process.env.REACT_APP_API_SERVER}files/${userID}/${d}`)
  return(`${process.env.REACT_APP_API_SERVER}files/${id}/${d}`)
}

  return (
    <>
    <br/><br/>
      {isLoading &&
      <Alert variant="warning">Loading...</Alert>
      
      }
<Form onSubmit={save}>
        <img src={`${process.env.REACT_APP_API_SERVER}files/${record.UUID}/${9879879}`} /><br/><br/>
        <b>{record.ProfileIsPublic==1 ? 'ΔΗΜΟΣΙΟ PROFILΕ' : 'ΚΛΕΙΣΤΟ ΜΟΝΟ ΓΙΑ ΦΙΛΟΥΣ PROFILΕ'}</b>
        <hr/>
        <Form.Group controlId="formBasicEmail">
            <Form.Label><b>Όνομα</b></Form.Label>
            <Form.Control disabled type="text"  required value={record.FirstName} onChange={(e)=>setRecord({...record,FirstName:e.target.value})}/>
        </Form.Group> 

        <Form.Group controlId="formBasicEmail">
            <Form.Label><b>Όνομα</b></Form.Label>
            <Form.Control disabled type="text"  required value={record.LastName} onChange={(e)=>setRecord({...record,LastName:e.target.value})}/>
        </Form.Group> 

        <br/>
        <Form.Group controlId="formBasicEmail">
            <Form.Label><b>Χώρα</b></Form.Label>
            <Form.Control disabled type="text"  required value={record.Country} onChange={(e)=>setRecord({...record,Country:e.target.value})}/>
        </Form.Group> 

        <br/>
        <Form.Group controlId="formBasicEmail">
            <Form.Label><b>Email</b></Form.Label>
            <Form.Control disabled type="email"  required value={record.Email} onChange={(e)=>setRecord({...record,Email:e.target.value})}/>
        </Form.Group> 




    
        {/* <br/>
        <Form.Group controlId="formBasicEmail">
            <Form.Label><b>Password</b></Form.Label>
            <Form.Control disabled type="email"  required value={record.Password} onChange={(e)=>setRecord({...record,Password:e.target.value})}/>
        </Form.Group>  */}

        <br/>
        <Form.Group controlId="formBasicEmail">
            <Form.Label><b>Info</b></Form.Label>
            <br/>
            {record.PersonalInfo}  
            <br/><br/>
            <b>HEIGHT:</b> {record.Height}  (cm)
            <br/>
            <b>WEIGHT:</b> {record.Weight} (kgr)             
        </Form.Group> 

        <br/>
        <Form.Group>
            <Form.Label><b>Ενεργό</b></Form.Label>  
            <Form.Control as="select" value={record.IsActive} onChange={(e)=>setRecord({...record,IsActive:e.target.value})} style={{width:'200px'}}>
                <option value='1'>ΝΑΙ</option>
                <option value='0'>ΟΧΙ</option>
            </Form.Control>
        </Form.Group>


        <p align="right">

            {props.id!=0 &&
            <>
            <Button  variant="danger" style={{width:"150px"}} onClick={deleteRecord}>
                Διαγραφή
            </Button> 
            &nbsp;&nbsp;   
            </>          
            }

            <Button  variant="dark" type="submit" style={{width:"150px"}} >
                Αποθήκευση
            </Button>
        </p>
</Form>


  {props.id!=0 && 
    <>
    <hr/>
    <UserStats id={props.id} SportID={props.SportID}/>
    <br/><br/>
    <UserChallenges id={props.id}/>
    <br/><br/>
    <UserPosts id={props.id}/>
    </>
    }

    </>

  )
}
